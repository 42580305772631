<template>
  <Row>
    <v-col cols='3'>
      <v-select
        v-model='documentType'
        :items='items'
        label='Tipo de documento'
        @change='sendDocumentType'
      />
    </v-col>
  </Row>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'DocumentType',
    data: function () {
      return {
        documentType: null,
        items: [],
      };
    },
    mounted: function () {
      this.getAllType();
    },

    methods: {
      sendDocumentType: function () {
        this.$emit('document', this.documentType);
      },

      getAllType: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/stock-type',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.items = result.data;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
    },
  };

</script>
